<template>
  <v-card outlined>
    <v-card-title class="d-flex justify-space-between align-center">
      <div>
        <span>선정산 상세내역</span>
        <span class="ml-4 subtitle-1">{{ formatDate(date) }}</span>
      </div>
      <v-btn
        text
        color="primary"
        @click="goBack"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        목록으로
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- 오전 선정산 -->
        <v-card outlined class="mb-8">
          <v-card-title class="subtitle-1 font-weight-bold">
            오전 선정산 (지급 금액: {{ formatAmount(totalMorningEarlyPayment) }}원)
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">구분</th>
                    <th class="text-right">매출액</th>
                    <th class="text-right">매출건수</th>
                    <th class="text-right">카드배달 수수료</th>
                    <th class="text-right">정산대상금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in morningData"
                    :key="`morning-${item.titleCode}-${index}`"
                  >
                    <td>{{ item.title }}</td>
                    <td class="text-right">
                      {{ formatAmount(item.salesAmount) }}원
                    </td>
                    <td class="text-right">{{ item.salesCount }}건</td>
                    <td class="text-right">
                      {{ formatAmount(item.expectedFee) }}원
                    </td>
                    <td class="text-right">
                      {{ formatAmount(item.targetAmount) }}원
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-weight-bold">합계</td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(morningData.reduce((sum, item) => sum + item.salesAmount, 0)) }}원
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ morningData.reduce((sum, item) => sum + item.salesCount, 0) }}건
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(morningData.reduce((sum, item) => sum + item.expectedFee, 0)) }}원
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(morningData.reduce((sum, item) => sum + item.targetAmount, 0)) }}원
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>

            <v-simple-table class="mt-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">공제 항목</th>
                    <th class="text-right">금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in exludeMorningData" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-right">{{ formatAmount(item.amount) }}원</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-weight-bold">합계</td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(exludeMorningData.reduce((sum, item) => sum + item.amount, 0)) }}원
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <!-- 오후 선정산 -->
        <v-card outlined class="mb-8">
          <v-card-title class="subtitle-1 font-weight-bold">
            오후 선정산 (지급 금액: {{ formatAmount(totalAfternoonEarlyPayment) }}원)
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">구분</th>
                    <th class="text-right">매출액</th>
                    <th class="text-right">매출건수</th>
                    <th class="text-right">카드배달 수수료</th>
                    <th class="text-right">정산대상금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in afternoonData"
                    :key="`afternoon-${item.titleCode}-${index}`"
                  >
                    <td>{{ item.title }}</td>
                    <td class="text-right">
                      {{ formatAmount(item.salesAmount) }}원
                    </td>
                    <td class="text-right">{{ item.salesCount }}건</td>
                    <td class="text-right">
                      {{ formatAmount(item.expectedFee) }}원
                    </td>
                    <td class="text-right">
                      {{ formatAmount(item.targetAmount) }}원
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-weight-bold">합계</td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(afternoonData.reduce((sum, item) => sum + item.salesAmount, 0)) }}원
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ afternoonData.reduce((sum, item) => sum + item.salesCount, 0) }}건
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(afternoonData.reduce((sum, item) => sum + item.expectedFee, 0)) }}원
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(afternoonData.reduce((sum, item) => sum + item.targetAmount, 0)) }}원
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>

            <v-simple-table class="mt-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">공제 항목</th>
                    <th class="text-right">금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in excludeAfternoonData" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-right">{{ formatAmount(item.amount) }}원</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-weight-bold">합계</td>
                    <td class="text-right font-weight-bold">
                      {{ formatAmount(excludeAfternoonData.reduce((sum, item) => sum + item.amount, 0)) }}원
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <!-- 정산 -->
        <div>
          <h2 class="text-h6 font-weight-bold mb-4">정산</h2>
          <v-row>
            <v-col cols="12" md="4">
              <v-card outlined>
                <v-card-title>상환필요금액</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in earlyPaymentSettlementData.required"
                          :key="item.name"
                        >
                          <td>{{ item.name }}</td>
                          <td class="text-right">
                            {{ formatAmount(item.amount) }}원
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="font-weight-bold">합계</td>
                          <td class="text-right font-weight-bold">
                            {{ formatAmount(earlyPaymentSettlementData.required.reduce((sum, item) => sum + item.amount, 0)) }}원
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card outlined>
                <v-card-title>입금 금액</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in earlyPaymentSettlementData.actual"
                          :key="item.name"
                        >
                          <td>{{ item.name }}</td>
                          <td class="text-right">
                            {{ formatAmount(item.amount) }}원
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="font-weight-bold">합계</td>
                          <td class="text-right font-weight-bold">
                            {{ formatAmount(earlyPaymentSettlementData.actual.reduce((sum, item) => sum + item.amount, 0)) }}원
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card outlined>
                <v-card-title>차액</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in earlyPaymentSettlementData.difference"
                          :key="item.name"
                        >
                          <td>{{ item.name }}</td>
                          <td class="text-right">
                            {{ formatAmount(item.amount) }}원
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="font-weight-bold">합계</td>
                          <td class="text-right font-weight-bold">
                            {{ formatAmount(earlyPaymentSettlementData.difference.reduce((sum, item) => sum + item.amount, 0)) }}원
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SettlementDetail',
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    settlementId: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      morningData: [],
      exludeMorningData: [],
      afternoonData: [],
      excludeAfternoonData: [],
      earlyPaymentSettlementData: {
        required: [],
        actual: [],
        difference: [],
      },
    }
  },
  computed: {
    totalMorningEarlyPayment() {
      return (
        this.morningData.reduce(
          (acc, cur) => acc + Number(cur.targetAmount),
          0
        ) -
        this.exludeMorningData.reduce((acc, cur) => acc + Number(cur.amount), 0)
      )
    },
    totalAfternoonEarlyPayment() {
      return (
        this.afternoonData.reduce(
          (acc, cur) => acc + Number(cur.targetAmount),
          0
        ) -
        this.excludeAfternoonData.reduce(
          (acc, cur) => acc + Number(cur.amount),
          0
        )
      )
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}`
    },
    formatAmount(amount) {
      return Number(amount || 0).toLocaleString()
    },
    async loadData() {
      try {
        this.loading = true
        const response = await this.$myfinbizApi.customer.getSettlementDetail({
          customerId: this.customerId,
          date: this.date,
        })

        // 매출 데이터 처리
        let salesData = Array.isArray(response.data.data.data)
          ? response.data.data.data.map((d) => {
            d.salesAmount = Number(d.salesAmount)
            d.salesCount = Number(d.salesCount)
            d.expectedFee = Number(d.expectedFee)
            d.exclude_amount = 0
            d.targetAmount = Number(d.targetAmount)
            d.title = d.title || d.paymentCompany || d.titleCode
            return d
          })
          : []

        // 오전 선정산 데이터
        this.morningData = salesData.filter(
          (d) => d.titleCode === 'am_nice_delivery'
        )
        const exMorning = Array.isArray(response.data.data.earlyPaymentDeduction)
          ? response.data.data.earlyPaymentDeduction.find(
            (d) => d.titleCode === 'am_nice_delivery'
          )
          : null

        console.log('오전 공제 데이터:', exMorning)

        if (exMorning) {
          const earlyPaymentRefund = exMorning.earlyPaymentRefund === 0
            ? 0
            : exMorning.earlyPaymentRefund * -1

          this.exludeMorningData = [
            {
              name: '카드 + 배달 추가입금',
              amount: earlyPaymentRefund,
            },
            {
              name: '미정산금',
              amount: exMorning.earlyPaymentAdjust || 0,
            },
            {
              name: '선정산 이자',
              amount:
                (exMorning.earlyPaymentInterest || 0) + (exMorning.earlyPaymentFee || 0),
            },
            {
              name: '운영 수수료(집금대행)',
              amount: exMorning.serviceFee || 0,
            },
            {
              name: '당겨받기 상환금',
              amount: exMorning.loanRepayment || 0,
            },
            {
              name: '이체수수료',
              amount: exMorning.bankFee || 0,
            },
          ]
          console.log('처리된 오전 공제 데이터:', this.exludeMorningData)
        }

        // 오후 선정산 데이터
        this.afternoonData = salesData.filter(
          (d) => d.titleCode !== 'am_nice_delivery'
        )
        const exAfternoon = Array.isArray(response.data.earlyPaymentDeduction)
          ? response.data.earlyPaymentDeduction.find(
            (d) => d.titleCode !== 'am_nice_delivery'
          )
          : null

        console.log('오후 공제 데이터:', exAfternoon)

        if (exAfternoon) {
          const earlyPaymentRefund = exAfternoon.earlyPaymentRefund === 0
            ? 0
            : exAfternoon.earlyPaymentRefund * -1

          this.excludeAfternoonData = [
            {
              name: '카드 + 배달 추가입금',
              amount: earlyPaymentRefund,
            },
            {
              name: '미정산금',
              amount: exAfternoon.earlyPaymentAdjust || 0,
            },
            {
              name: '선정산 이자',
              amount:
                (exAfternoon.earlyPaymentInterest || 0) + (exAfternoon.earlyPaymentFee || 0),
            },
            {
              name: '운영 수수료',
              amount: exAfternoon.serviceFee || 0,
            },
            {
              name: '당겨받기 상환금',
              amount: exAfternoon.loanRepayment || 0,
            },
            {
              name: '이체수수료',
              amount: exAfternoon.bankFee || 0,
            },
          ]
          console.log('처리된 오후 공제 데이터:', this.excludeAfternoonData)
        }

        // 정산 데이터
        const settlement = response.data.earlyPaymentSettlement
        console.log('정산 데이터:', settlement)

        if (settlement && Object.keys(settlement).length > 0) {
          this.earlyPaymentSettlementData = {
            required: [
              { name: '원금', amount: settlement.required?.principal || 0 },
              { name: '이자', amount: settlement.required?.interest || 0 },
            ],
            actual: [
              { name: '카드/배달 입금', amount: settlement.actual?.paid || 0 },
              {
                name: '선정산 공제',
                amount: settlement.actual?.deduction || 0,
              },
            ],
            difference: [
              { name: '부족분', amount: settlement.difference?.deficit || 0 },
              { name: '반환금', amount: settlement.difference?.refund || 0 },
            ],
          }
          console.log('처리된 정산 데이터:', this.earlyPaymentSettlementData)
        } else {
          // 데이터가 없을 경우 기본값 설정
          this.earlyPaymentSettlementData = {
            required: [
              { name: '원금', amount: 0 },
              { name: '이자', amount: 0 },
            ],
            actual: [
              { name: '카드/배달 입금', amount: 0 },
              { name: '선정산 공제', amount: 0 },
            ],
            difference: [
              { name: '부족분', amount: 0 },
              { name: '반환금', amount: 0 },
            ],
          }
        }
      } catch (error) {
        console.error('Failed to load settlement detail:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '선정산 상세 내역을 불러오는데 실패했습니다.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    getPaymentCompanyName(code) {
      const paymentCompanies = {
        '04': '국민카드',
        13: '롯데카드',
        // 필요한 카드사 코드 추가
      }
      return paymentCompanies[code] || code
    },
    goBack() {
      // 이전 페이지가 정산 정보 페이지인 경우
      if (this.$route.query.from === 'settlement') {
        // 저장된 상태 복원
        const savedState = localStorage.getItem('settlementInfoState')
        if (savedState) {
          const state = JSON.parse(savedState)
          // 상태와 함께 이동
          this.$router.push({
            path: `/myfinbiz/customer/${this.customerId}`,
            query: {
              tab: '1',
              ...state
            }
          })
        } else {
          // 저장된 상태가 없는 경우 기본 이동
          this.$router.push({
            path: `/myfinbiz/customer/${this.customerId}`,
            query: { tab: '1' }
          })
        }
        // 저장된 상태 삭제
        localStorage.removeItem('settlementInfoState')
      } else {
        // 다른 경우 기본 이동
        this.$router.push({
          path: `/myfinbiz/customer/${this.customerId}`,
          query: { tab: '1' }
        })
      }
    },
  },
  created() {
    this.loadData()
  },
}
</script>

<style scoped>
.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}
</style>
